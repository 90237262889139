<script>
import { ArrowUpIcon, HeartIcon, EyeIcon, ShoppingCartIcon } from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import API from "@/core/api";
import IndexService from "@/MainServices/IndexService.js";
import Skeleton from "@/view/front/components/skeleton";
import Button from "../../pages/vue-bootstrap/Button.vue";
import { mapGetters, mapState } from "vuex";
import AdmissionForm from "@/MainServices/AdmissionForm.js";

/**
 * Shop-product-detail component
 */
export default {
  data() {
    return {
      countval: 1,
      API: API.API_HOME,
      country: this.$route.params.country,
      city: this.$route.params.city,
      uni_name: this.$route.params.uni_name,
      level: this.$route.params.level,
      slug: this.$route.params.slug,
      course_details: [],
      loading: true,
      showAdmissionForm: false,
      formData: [],
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
    VueSlickCarousel,
    HeartIcon,
    EyeIcon,
    ShoppingCartIcon,
    Skeleton,
  },
  methods: {
    increment() {
      this.countval++;
    },
    decrement() {
      this.countval--;
    },
    show_admission_form() {
      if (this.currentUser && this.currentUser.role == "student") {
        this.showAdmissionForm = true;
      } else {
        this.$notify({
          group: "foo",
          type: "error",
          duration: 100000,
          title: "Important message",
          text: "Please Login Into Your Student Account For Apply in this course!",
        });
      }
    },
    cancel_admission_form() {
      this.showAdmissionForm = false;
    },
    submit_admission_form() {
      let data = [];
      this.formData.map((el) => {
        el.fields.map((el2) => {
          data.push({ id: el2.id, value: el2.value, label: el2.label });
        });
      });
      let form_data = {
        user_id: this.currentUser.id,
        listing_id: this.course_details.id,
        tamplate_id: this.course_details.form_id,
        values: JSON.stringify(data),
      };
      AdmissionForm.submitApplicationForm(form_data)
        .then((res) => {
          this.showAdmissionForm = false;
          this.$notify({
            group: "foo",
            type: "success",
            duration: 100000,
            title: "Important message",
            text:
              "Your application has been submited, you may check the status in your dashboard!",
          });
        })
        .catch((error) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 100000,
            title: "Important message",
            text: "Something Went Wrong, Please try latter!",
          });
        });
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    IndexService.getSingleCourse(
      this.country,
      this.city,
      this.uni_name,
      this.level,
      this.slug
    ).then((response) => {
      this.course_details = response.data[0];
      response.data[0].form_tabs.map((tabs) => {
        let store_local = {
          id: tabs.id,
          name: tabs.name,
          sequence: tabs.sequence,
          fields: [],
        };
        tabs.fields.map((field) => {
          if (field.options !== "1") {
            field.storeOptions = [];
            let storeOptionsInArray = field.options.split(",");
            storeOptionsInArray.map((el) => {
              field.storeOptions.push({ value: el, text: el });
            });
          }
          store_local.fields.push({
            id: field.id,
            caption: field.caption,
            sequence: field.sequence,
            tab_id: field.tab_id,
            label: field.label,
            type: field.type,
            length: field.length,
            frontend: field.frontend,
            options: field.storeOptions,
            editable: field.editable,
            value: null,
          });
        });
        this.formData.push(store_local);
      });
      this.loading = false;
    });
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-custom bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">{{ course_details.user }}</h4>
              <div class="page-next"></div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->

    <section class="section">
      <div class="container">
        <Skeleton v-if="loading" />
        <div class="row" v-if="!loading">
          <div class="col-md-3">
            <VueSlickCarousel
              ref="c1"
              :arrows="false"
              :focusOnSelect="true"
              :asNavFor="$refs.c2"
              :dots="false"
              :autoplay="true"
              :fade="true"
            >
              <div v-for="(image, index) in course_details.images" :key="index">
                <img :src="API + image.image_path" class="img-fluid" alt="" />
              </div>
            </VueSlickCarousel>

            <VueSlickCarousel
              :asNavFor="$refs.c1"
              :arrows="false"
              :focusOnSelect="true"
              ref="c2"
              class="slick-slide"
              :slidesToShow="3"
            >
              <div v-for="(image, index) in course_details.images" :key="index">
                <img :src="API + image.image_path" class="img-fluid" alt="" />
              </div>
            </VueSlickCarousel>
          </div>
          <!--end col-->
          <div class="col-md-9 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="section-title ml-md-4">
              <h4 class="title">{{ course_details.title }}</h4>

              <ul class="list-unstyled text-warning h5 mb-0">
                <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
              </ul>

              <div class="single-course-details">
                <div class="listing-institute">
                  <span class="w2dc-field-caption"
                    ><span class="icon-home"></span
                    ><span class="w2dc-field-name"
                      ><i class="mdi mdi-school-outline icon-custom"></i> &nbsp;
                      Institute:</span
                    ></span
                  ><span class="w2dc-field-content">{{ course_details.user }}</span>
                </div>
                <div class="listing-institute" style="display: flex">
                  <span class="w2dc-field-caption campus-name"
                    ><span class="icon-location"></span
                    ><span class="w2dc-field-name"
                      ><i class="mdi mdi-map-marker icon-custom"></i> &nbsp; Campus:</span
                    ></span
                  ><span class="w2dc-field-content"
                    >{{ course_details.campus }}&nbsp;,&nbsp;{{
                      course_details.city
                    }}</span
                  >
                </div>
                <div class="listing-institute">
                  <span class="w2dc-field-caption"
                    ><span class="icon-phone-hang-up"></span
                    ><span class="w2dc-field-name"
                      ><i class="mdi mdi-phone-message icon-custom"></i> &nbsp; Phone
                      Number:</span
                    ></span
                  ><span class="w2dc-field-content">{{
                    course_details.campus_contact
                  }}</span>
                </div>
                <div class="listing-institute">
                  <span class="w2dc-field-caption"
                    ><span class="icon-user-tie"></span
                    ><span class="w2dc-field-name"
                      ><i class="mdi mdi-card-account-details icon-custom"></i> &nbsp;
                      Program Level:</span
                    ></span
                  ><span class="w2dc-field-content">{{ course_details.level }}</span>
                </div>
                <div class="listing-institute">
                  <span class="w2dc-field-caption"
                    ><span class="icon-clock2"></span
                    ><span class="w2dc-field-name"
                      ><i class="mdi mdi-account-clock-outline icon-custom"></i> &nbsp;
                      Duration:</span
                    ></span
                  ><span class="w2dc-field-content">{{ course_details.duration }}</span>
                </div>
              </div>
              <h5 class="mt-4 py-2">Overview :</h5>
              <p v-html="course_details.description"></p>
              <div class="mt-4 pt-2">
                <a href="javascript:void(0)" class="btn btn-primary">View Details</a>
                <button
                  type="button"
                  class="btn btn-soft-primary ml-2"
                  @click="show_admission_form"
                >
                  Apply Online
                </button>
              </div>
            </div>
          </div>
          <!--end col-->

          <div
            class="col-md-12 pt-5 mt-10"
            v-if="showAdmissionForm && currentUser"
            style="border: 1px solid"
          >
            <div class="section-title ml-md-4">
              <h1>{{ course_details.form }}</h1>
            </div>
            <div class="col-md-12 pt-5 mt-10" style="border: 1px solid">
              <h2>Student Info</h2>
              <div class="col-md-12 row">
                <div class="col-md-3 pt-2 mt-5 form-group position-relative">
                  <label>Student Email <span class="text-danger">*</span></label>
                  <input :value="currentUser.email" class="form-control pl-5" disabled />
                </div>
                <div class="col-md-3 pt-2 mt-5 form-group position-relative">
                  <label>Student Name <span class="text-danger">*</span></label>
                  <input :value="currentUser.name" class="form-control pl-5" disabled />
                </div>
              </div>
            </div>
            <div
              class="col-md-12 pt-2 mt-5"
              v-for="(tab, index) in formData"
              :key="index"
            >
              <h2 style="border-bottom: 1px solid">{{ tab.name }}</h2>
              <div class="col-md-12 row">
                <div
                  class="col-md-3 pt-2 mt-5 form-group position-relative"
                  v-for="(field, index) in tab.fields"
                  :key="index"
                >
                  <label>{{ field.label }} <span class="text-danger">*</span></label>
                  <input
                    v-model="field.value"
                    :type="field.type"
                    :placeholder="field.caption"
                    :maxlength="field.length"
                    v-if="field.tab_id == tab.id && field.type !== 'select'"
                    class="form-control pl-5"
                  />
                  <v-autocomplete
                    v-model="field.value"
                    :items="field.options"
                    class="form-control pl-5"
                    v-if="field.tab_id == tab.id && field.type === 'select'"
                    style="padding-top: 2px; margin-top: 3px"
                  ></v-autocomplete>
                </div>
              </div>
            </div>
            <div class="mt-4 pt-2">
              <button
                type="button"
                class="btn btn-primary"
                @click="submit_admission_form"
              >
                Submit Form
              </button>
              <button
                type="button"
                class="btn btn-soft-danger ml-2"
                @click="cancel_admission_form"
              >
                Cancel Form
              </button>
            </div>
            <br />
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row">
          <div class="col-12">
            <h5 class="mb-0">Related Products</h5>
          </div>
          <!--end col-->

          <div class="col-12 mt-4">
            <carousel
              id="client-four"
              class="owl-carousel owl-theme"
              dir="ltr"
              :per-page="3"
            >
              <Slide>
                <div
                  class="card shop-list border-0 position-relative overflow-hidden m-2"
                >
                  <div
                    class="shop-image position-relative overflow-hidden rounded shadow"
                  >
                    <router-link to="/shop-product-detail"
                      ><img src="images/shop/product/s1.jpg" class="img-fluid" alt=""
                    /></router-link>
                    <router-link to="/shop-product-detail" class="overlay-work">
                      <img src="images/shop/product/s-1.jpg" class="img-fluid" alt="" />
                    </router-link>
                    <ul class="list-unstyled shop-icons">
                      <li>
                        <a
                          href="javascript:void(0)"
                          class="btn btn-icon btn-pills btn-soft-danger"
                        >
                          <heart-icon class="icons"></heart-icon>
                        </a>
                      </li>
                      <li class="mt-2">
                        <a
                          href="shop-product-detail"
                          class="btn btn-icon btn-pills btn-soft-primary"
                        >
                          <eye-icon class="icons"></eye-icon>
                        </a>
                      </li>
                      <li class="mt-2">
                        <router-link
                          to="/shop-cart"
                          class="btn btn-icon btn-pills btn-soft-warning"
                        >
                          <shopping-cart-icon class="icons"></shopping-cart-icon>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body content pt-4 p-2">
                    <router-link
                      to="/shop-product-detail"
                      class="text-dark product-name h6"
                      >Branded T-Shirt</router-link
                    >
                    <div class="d-flex justify-content-between mt-1">
                      <h6 class="icon-custom small font-italic mb-0 mt-1">
                        $16.00 <del class="text-danger ml-2">$21.00</del>
                      </h6>
                      <ul class="list-unstyled text-warning mb-0">
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="card shop-list border-0 position-relative overflow-hidden m-2"
                >
                  <div
                    class="shop-image position-relative overflow-hidden rounded shadow"
                  >
                    <router-link to="/shop-product-detail"
                      ><img src="images/shop/product/s3.jpg" class="img-fluid" alt=""
                    /></router-link>
                    <router-link to="/shop-product-detail" class="overlay-work">
                      <img src="images/shop/product/s-3.jpg" class="img-fluid" alt="" />
                    </router-link>
                    <ul class="list-unstyled shop-icons">
                      <li>
                        <a
                          href="javascript:void(0)"
                          class="btn btn-icon btn-pills btn-soft-danger"
                        >
                          <heart-icon class="icons"></heart-icon>
                        </a>
                      </li>
                      <li class="mt-2">
                        <a
                          href="shop-product-detail"
                          class="btn btn-icon btn-pills btn-soft-primary"
                        >
                          <eye-icon class="icons"></eye-icon>
                        </a>
                      </li>
                      <li class="mt-2">
                        <router-link
                          to="/shop-cart"
                          class="btn btn-icon btn-pills btn-soft-warning"
                        >
                          <shopping-cart-icon class="icons"></shopping-cart-icon>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body content pt-4 p-2">
                    <router-link
                      to="/shop-product-detail"
                      class="text-dark product-name h6"
                      >Elegent Watch</router-link
                    >
                    <div class="d-flex justify-content-between mt-1">
                      <h6 class="icon-custom small font-italic mb-0 mt-1">
                        $5.00 <span class="text-success ml-1">30% off</span>
                      </h6>
                      <ul class="list-unstyled text-warning mb-0">
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="card shop-list border-0 position-relative overflow-hidden m-2"
                >
                  <div
                    class="shop-image position-relative overflow-hidden rounded shadow"
                  >
                    <router-link to="/shop-product-detail"
                      ><img src="images/shop/product/s6.jpg" class="img-fluid" alt=""
                    /></router-link>
                    <router-link to="/shop-product-detail" class="overlay-work">
                      <img src="images/shop/product/s-6.jpg" class="img-fluid" alt="" />
                    </router-link>
                    <ul class="list-unstyled shop-icons">
                      <li>
                        <a
                          href="javascript:void(0)"
                          class="btn btn-icon btn-pills btn-soft-danger"
                        >
                          <heart-icon class="icons"></heart-icon>
                        </a>
                      </li>
                      <li class="mt-2">
                        <a
                          href="shop-product-detail"
                          class="btn btn-icon btn-pills btn-soft-primary"
                        >
                          <eye-icon class="icons"></eye-icon>
                        </a>
                      </li>
                      <li class="mt-2">
                        <router-link
                          to="/shop-cart"
                          class="btn btn-icon btn-pills btn-soft-warning"
                        >
                          <shopping-cart-icon class="icons"></shopping-cart-icon>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body content pt-4 p-2">
                    <router-link
                      to="/shop-product-detail"
                      class="text-dark product-name h6"
                      >Elegent Mug</router-link
                    >
                    <div class="d-flex justify-content-between mt-1">
                      <h6 class="icon-custom small font-italic mb-0 mt-1">
                        $4.50 <del class="text-danger ml-2">$6.50</del>
                      </h6>
                      <ul class="list-unstyled text-warning mb-0">
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="card shop-list border-0 position-relative overflow-hidden m-2"
                >
                  <div
                    class="shop-image position-relative overflow-hidden rounded shadow"
                  >
                    <router-link to="/shop-product-detail"
                      ><img src="images/shop/product/s8.jpg" class="img-fluid" alt=""
                    /></router-link>
                    <router-link to="/shop-product-detail" class="overlay-work">
                      <img src="images/shop/product/s-8.jpg" class="img-fluid" alt="" />
                    </router-link>
                    <ul class="list-unstyled shop-icons">
                      <li>
                        <a
                          href="javascript:void(0)"
                          class="btn btn-icon btn-pills btn-soft-danger"
                        >
                          <heart-icon class="icons"></heart-icon>
                        </a>
                      </li>
                      <li class="mt-2">
                        <router-link
                          to="/shop-product-detail"
                          class="btn btn-icon btn-pills btn-soft-primary"
                        >
                          <eye-icon class="icons"></eye-icon>
                        </router-link>
                      </li>
                      <li class="mt-2">
                        <router-link
                          to="/shop-cart"
                          class="btn btn-icon btn-pills btn-soft-warning"
                        >
                          <shopping-cart-icon class="icons"></shopping-cart-icon>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body content pt-4 p-2">
                    <router-link
                      to="/shop-product-detail"
                      class="text-dark product-name h6"
                      >Wooden Stools</router-link
                    >
                    <div class="d-flex justify-content-between mt-1">
                      <h6 class="icon-custom small font-italic mb-0 mt-1">
                        $22.00 <del class="text-danger ml-2">$25.00</del>
                      </h6>
                      <ul class="list-unstyled text-warning mb-0">
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="card shop-list border-0 position-relative overflow-hidden m-2"
                >
                  <div
                    class="shop-image position-relative overflow-hidden rounded shadow"
                  >
                    <router-link to="/shop-product-detail"
                      ><img src="images/shop/product/s14.jpg" class="img-fluid" alt=""
                    /></router-link>
                    <router-link to="/shop-product-detail" class="overlay-work">
                      <img src="images/shop/product/s-14.jpg" class="img-fluid" alt="" />
                    </router-link>
                    <ul class="list-unstyled shop-icons">
                      <li>
                        <a
                          href="javascript:void(0)"
                          class="btn btn-icon btn-pills btn-soft-danger"
                        >
                          <heart-icon class="icons"></heart-icon>
                        </a>
                      </li>
                      <li class="mt-2">
                        <router-link
                          to="/shop-product-detail"
                          class="btn btn-icon btn-pills btn-soft-primary"
                        >
                          <eye-icon class="icons"></eye-icon>
                        </router-link>
                      </li>
                      <li class="mt-2">
                        <router-link
                          to="/shop-cart"
                          class="btn btn-icon btn-pills btn-soft-warning"
                        >
                          <shopping-cart-icon class="icons"></shopping-cart-icon>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body content pt-4 p-2">
                    <router-link
                      to="/shop-product-detail"
                      class="text-dark product-name h6"
                      >Women Block Heels</router-link
                    >
                    <div class="d-flex justify-content-between mt-1">
                      <h6 class="icon-custom small font-italic mb-0 mt-1">
                        $21.00 <del class="text-danger ml-2">$25.00</del>
                      </h6>
                      <ul class="list-unstyled text-warning mb-0">
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                        <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Slide>
            </carousel>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
